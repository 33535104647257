<template>
  <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
    <form @submit.prevent="handleSubmit(onSubmit)" ref="form">
      <div class="p-3">
        <div class="program-user-form mt-1">
          <card type="default" header-classes="bg-transparent" v-loading="loader">
            <h4 class="card-heading-text text-uppercase text-gray-400 font-weight-400 mb-4">BASIC INFO</h4>
            <div class="row">
              <div class="col-12 col-md-6">
                <base-input
                  name="Name"
                  label="Name*"
                  v-model="form.name"
                  :rules="{ custom_required: true }"
                  placeholder="Name"/>
              </div>
              <div class="col-12 col-md-6">
                <base-input
                  name="Email"
                  label="Email*"
                  v-model="form.email"
                  :rules="{ custom_required: true, email: true }"
                  placeholder="Email"/>
              </div>
              <div class="col-12 col-md-6">
                <base-input
                  name="User Name"
                  label="User Name*"
                  v-model="form.username"
                  :rules="{ custom_required: true }"
                  placeholder="User Name"/>
              </div>
              <div class="col-12 col-md-6" v-if="formType == 'store'">
                <base-input
                  type="password"
                  name="password"
                  label="Password*"
                  v-model="form.password"
                  :rules="{ custom_required: true }"
                  placeholder="Password"
                  autocomplete="new-password"/>
              </div>
              <div class="col-12 col-md-6" v-if="formType == 'store'">
                <base-input
                  type="password"
                  name="password_confirmation"
                  label="Confirm Password*"
                  v-model="form.password_confirmation"
                  :rules="{ custom_required: true, custom_confirmed:'password' }"
                  placeholder="Confirm Password"
                  autocomplete="confirm-password"/>
              </div>
              <div class="col-12 col-md-6">
                <base-select
                  name="Role"
                  label="Role"
                  custom-clearable
                  v-model="form.role"
                  :options="programUserRoles"/>
              </div>
              <div class="col-12 col-md-6">
                <base-select
                  name="Default Stage"
                  label="Default Stage"
                  custom-clearable
                  v-model="form.default_stage"
                  :options="defaultStages"/>
              </div>
            </div>
          </card>
          <card type="default" header-classes="bg-transparent" v-loading="loader">
            <h4 class="card-heading-text text-uppercase text-gray-400 font-weight-400 mb-4">PROGRAM INFO</h4>
            <div class="row">
              <div class="col-12 col-md-6">
                <base-select
                  filterable
                  name="Program Name"
                  label="Program Name*"
                  v-model="form.sub_user_id"
                  :options="programs"
                  :rules="{ custom_required: true }"
                  @change="prepareUserCategoriesWithSubCategories"/>
              </div>
              <div class="col-12 col-md-6" v-if="form.sub_user_id">
                <base-input
                  name="Program Code"
                  label="Program Code"
                  :disabled="true"
                  :placeholder="programCode"/>
              </div>
              <div class="col-12 col-md-6" v-if="form.sub_user_id">
                <base-input
                  name="Tier"
                  label="Tier"
                  :disabled="true"
                  :placeholder="programTier"/>
              </div>
            </div>
          </card>
          <card type="default" header-classes="bg-transparent" v-loading="loader" v-if="patientTagsWithSubTag.length">
            <h4 class="card-heading-text text-uppercase text-gray-400 font-weight-400 mb-4">DEFAULT PATIENT TAGS</h4>
            <div class="row">
              <div class="col-12 col-md-6" v-for="patientTag in patientTagsWithSubTag">
                <base-select
                  v-model="tagsArray[patientTag.id]"
                  :name="patientTag.name"
                  :label="patientTag.name"
                  custom-clearable
                  :options="patientTag.subTags">
                </base-select>
              </div>
            </div>
          </card>
          <card type="default" header-classes="bg-transparent" v-loading="loader">
            <h4 class="card-heading-text text-uppercase text-gray-400 font-weight-400 mb-4">DASHBOARD ACCESSING
              CHECKS</h4>
            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Administrative Rights</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.administrative_rights == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.administrative_rights = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.administrative_rights == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.administrative_rights = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="" class="form-control-label">Self Service Account</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :disabled="programTier == 1"
                                   :value="form.self_service == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="updateSelfServiceCheck(1)">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :disabled="programTier == 1"
                                   :value="form.self_service == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="updateSelfServiceCheck(0)">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Select All</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.all_check == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="updateAllCheck(1)">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.all_check == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="updateAllCheck(0)">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Contact Us</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.contact == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.contact = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.contact == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.contact = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Manage Users</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.manage_user == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.manage_user = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.manage_user == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.manage_user = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Manage Events</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.event == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.event = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.event == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.event = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Instructions</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.instruction == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.instruction = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.instruction == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.instruction = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Diet</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.diet == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.diet = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.diet == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.diet = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Schedule Notifications</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.s_notifications == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.s_notifications = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.s_notifications == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.s_notifications = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Conditional Notifications</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.c_notifications == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.c_notifications = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.c_notifications == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.c_notifications = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">FAQ's</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.faq == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.faq = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.faq == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.faq = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Our Team</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.our_team == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.our_team = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.our_team == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.our_team = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Online Seminar</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.online_seminar == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.online_seminar = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.online_seminar == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.online_seminar = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Our Website</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.our_website == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.our_website = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.our_website == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.our_website = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">About Surgery</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.about_surgery == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.about_surgery = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.about_surgery == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.about_surgery = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Videos</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.videos == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.videos = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.videos == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.videos = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Vitamin and Supplements</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.vitamin_supplement == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.vitamin_supplement = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.vitamin_supplement == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.vitamin_supplement = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">After and Before</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.after_before == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.after_before = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.after_before == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.after_before = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Patient Portal</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.patient_portal == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.patient_portal = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.patient_portal == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.patient_portal = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Non surgeries weight loss</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.non_surgeries_wight == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.non_surgeries_wight = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.non_surgeries_wight == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.non_surgeries_wight = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Program Logo</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.program_logo == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.program_logo = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.program_logo == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.program_logo = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Alerts</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.alert_notification == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.alert_notification = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.alert_notification == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.alert_notification = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Support Group</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.support_group == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.support_group = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.support_group == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.support_group = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Support Services</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.support_services == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.support_services = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.support_services == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.support_services = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Join Us On Facebook</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.join_us_facebook == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.join_us_facebook = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.join_us_facebook == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.join_us_facebook = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Our Blog</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.our_blog == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.our_blog = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.our_blog == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.our_blog = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Shop Url</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.shop_url == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.shop_url = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.shop_url == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.shop_url = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Seminar to Surgery</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.seminar_to_surgery == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.seminar_to_surgery = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.seminar_to_surgery == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.seminar_to_surgery = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Newsletter</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.Newsletter == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.Newsletter = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.Newsletter == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.Newsletter = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6" v-if="form.self_service == 0">
                <div class="form-group">
                  <label for="" class="form-control-label">Pipeline</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.pipeline_status == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.pipeline_status = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.pipeline_status == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.pipeline_status = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
          <card type="default" header-classes="bg-transparent" v-loading="loader" v-if="formType == 'store'">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="" class="form-control-label">Do you wish to invite this Program User using email ?</label>
                  <div class="row">
                    <div class="col-6">
                      <el-checkbox label="Yes" border
                                   :value="form.send_email_check == 1"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.send_email_check = 1">YES
                      </el-checkbox>
                    </div>
                    <div class="col-6">
                      <el-checkbox label="0" border
                                   :value="form.send_email_check == 0"
                                   class="checkbox-custom checkbox-custom-bari w-100"
                                   @change="(value) => form.send_email_check = 0">NO
                      </el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="mb-4 ml-3">
        <base-button native-type="submit" type="dark-blue" class="px-5" size="xl" :disabled="loader">
          {{ submitButton }}
        </base-button>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin'
import {constants} from '@/util/constants'
import _ from "lodash";

export default {
  props: {
    programUser: Object,
    loader: Boolean,
    formType: String
  },
  components: {},
  mixins: [generateErrorMessageMixin],
  data() {
    return {
      form: {
        ...this.$store.getters['ProgramUserModule/getProgramUser']
      },
      selectAllFormFields: false,
      response: {
        programsWithPatientTags: []
      },
      programUserRoles: constants.programUser.roleOptions,
      defaultStages: constants.common.defaultStages,
      patientTagsWithSubTag: [],
      programs: [],
      tagsArray: {},
    }
  },
  mounted() {
    this.getProgramWithCategories()

    if (this.programUser) {
      this.setValues(this.programUser)
    }
  },
  computed: {
    submitButton() {
      return this.formType == 'store' ? 'Add' : 'Update'
    },

    programCode() {
      return this.programs?.find(program => program.value == this.form.sub_user_id)?.code ?? ''
    },

    programTier() {
      return this.programs?.find(program => program.value == this.form.sub_user_id)?.tier ?? ''
    }
  },
  watch: {
    'programUser': function (newValue) {
      this.setValues(newValue)
    },

    'programTier': function (newVal) {
      if (newVal == 1) this.updateSelfServiceCheck(1)
    },
  },
  methods: {
    /**
     * Update All checks for a Program USer
     * @param status
     */
    updateAllCheck(status) {
      this.form.self_service = 0
      this.form.all_check = status
      this.form.contact = status
      this.form.manage_user = status
      this.form.event = status
      this.form.instruction = status
      this.form.diet = status
      this.form.s_notifications = status
      this.form.c_notifications = status
      this.form.faq = status
      this.form.our_team = status
      this.form.online_seminar = status
      this.form.our_website = status
      this.form.about_surgery = status
      this.form.videos = status
      this.form.vitamin_supplement = status
      this.form.after_before = status
      this.form.patient_portal = status
      this.form.non_surgeries_wight = status
      this.form.program_logo = status
      this.form.alert_notification = status
      this.form.support_group = status
      this.form.support_services = status
      this.form.join_us_facebook = status
      this.form.our_blog = status
      this.form.shop_url = status
      this.form.seminar_to_surgery = status
      this.form.Newsletter = status
      this.form.pipeline_status = status
    },

    /**
     * Update Self Service Check
     * @param status
     */
    updateSelfServiceCheck(status) {
      this.form.self_service = status
      this.form.administrative_rights = 0
      this.form.all_check = 0
      this.form.contact = 0
      this.form.manage_user = 0
      this.form.event = 0
      this.form.instruction = 0
      this.form.diet = 0
      this.form.s_notifications = 0
      this.form.c_notifications = 0
      this.form.faq = 0
      this.form.our_team = 0
      this.form.online_seminar = 0
      this.form.our_website = 0
      this.form.about_surgery = 0
      this.form.videos = 0
      this.form.vitamin_supplement = 0
      this.form.after_before = 0
      this.form.patient_portal = 0
      this.form.non_surgeries_wight = 0
      this.form.program_logo = 0
      this.form.alert_notification = 0
      this.form.support_group = 0
      this.form.support_services = 0
      this.form.join_us_facebook = 0
      this.form.our_blog = 0
      this.form.shop_url = 0
      this.form.seminar_to_surgery = 0
      this.form.Newsletter = 0
      this.form.pipeline_status = 0
    },

    /**
     * Get All active Programs with categories associated with it
     */
    getProgramWithCategories() {
      let vm = this
      vm.$store.dispatch('BaritasticProgramModule/_getProgramsWithPatientTags')
        .then(response => {
          vm.response.programsWithPatientTags = response.data.data
          vm.prepareProgramsData(response.data.data)
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Program With Patient Tags',
            message: message
          })
        })
    },

    /**
     * Prepare User-Categories with Sub-Categories associated with it
     */
    prepareUserCategoriesWithSubCategories() {
      this.patientTagsWithSubTag = []
      this.tagsArray = {}
      this.response.programsWithPatientTags.forEach(program => {
        if (program.id == this.form.sub_user_id) {
          program.user_categories.forEach(userCategory => {
            let userSubCategoriesArray = []

            //Prepare Sub-Categories array
            userCategory.user_sub_categories.forEach(userSubCategory => {
              userSubCategoriesArray.push(
                {
                  label: userSubCategory.name,
                  value: userSubCategory.id
                }
              )
            })

            //Add object to User-Categories with Sub-Categories
            this.patientTagsWithSubTag.push(
              {
                name: userCategory.name,
                id: userCategory.id,
                subTags: userSubCategoriesArray
              }
            )

            //Prepare tagsArray for each User-Category
            this.tagsArray = {...this.tagsArray, [userCategory.id]: ''}
          })
        }
      })
    },

    /**
     * Prepare Program Array from data
     * @param data
     */
    prepareProgramsData(data) {
      this.patientTagsWithSubTag = []
      this.programs = []
      this.tagsArray = {}
      data.forEach(program => {
        this.programs.push({
          label: program.first_name,
          value: program.id,
          code: program.programmer_code,
          tier: program.tier
        })
      })
    },

    /**
     * Set values for object
     * @param object
     */
    setValues(object) {
      this.form = _.cloneDeep(object)
      if (object.self_service == 1) {
        this.updateSelfServiceCheck(object.self_service)
      } else if (object.all_check == 1) {
        this.updateAllCheck(object.all_check)
      }

      this.prepareUserCategoriesWithSubCategories()
      this.updatePatientTagsArray(object.default_categories)
    },

    /**
     * Update Patient Tags Array
     * @param defaultCategories
     */
    updatePatientTagsArray(defaultCategories) {
      defaultCategories.forEach(defaultCategory => {
        this.tagsArray[defaultCategory['user_category_id']] = defaultCategory['user_sub_category_id']
      })
    },

    /**
     * On Submit Form Action
     */
    onSubmit() {
      //Make Default Categories Object
      const defaultCategories = Object.entries(this.tagsArray)
        .filter(([user_category_id, user_sub_category_id]) => user_sub_category_id !== null && user_sub_category_id !== '')
        .map(([user_category_id, user_sub_category_id]) => ({
          user_category_id,
          user_sub_category_id,
          user_type: 'program_user'
        }))

      if (this.formType == 'store') {
        this.$emit('store', {
          form: this.form,
          defaultCategories
        })
      } else if (this.formType == 'update') {
        this.$emit('update', {
          form: this.form,
          defaultCategories,
          id: this.programUser.id
        })
      }
    }
  },
}
</script>

<style lang="scss">
.program-user-form {
  .form-group {
    margin-bottom: 0.65rem !important;
  }

  input {
    &.form-control {
      height: calc(1.5em + 1.25rem + 2px);
    }
  }
}
</style>
